<template>
  <div>
    <!-- Menu -->
    <v-navigation-drawer app permanent :mini-variant.sync="mini" width="290">
      <!-- Cabeçalho do menu -->
      <template v-slot:prepend>
        <!-- Parte do app-bar em cima do menu -->
        <v-toolbar id="toolbar-logo" flat class="elevation-4">
          <v-list-item class="pa-0 mx-n1">
            <!-- Imagem da logo -->
            <router-link to="/" title="Ir para o início" class="d-inline-block">
              <v-img
                v-if="!mini"
                src="@/assets/img/logo.png"
                alt="Logo"
                transition="none"
                width="100"
              />

              <img
                v-if="mini"
                src="/img/icons/apple-touch-icon.png"
                alt="Logo"
                class="mt-1 mr-4"
                style="width: 32px"
              />
            </router-link>
          </v-list-item>

          <AvatarMenu v-if="!mini" />
        </v-toolbar>
      </template>
      <v-select
        v-if="empresas.length > 1 && !mini"
        v-model="empresa"
        dense
        item-text="nome"
        no-data-text="Nenhuma empresa encontrada"
        :items="empresas"
        class="mb-0 pa-4"
        return-object
        hide-details=""
      >
      </v-select>
      <v-list class="pa-0">
        <v-list-item-group>
          <v-divider></v-divider>

          <v-list-group
            v-if="checkCredentials(['admin', 'dashboard'])"
            :value="false"
            prepend-icon="mdi-view-dashboard"
            no-action
            :append-icon="dashboards.length > 0 ? 'mdi-chevron-down' : null"
            @click="dashboards.length == 0 ? $router.push('/dashboard').catch(() => {}) : null"
          >
            <template v-slot:activator>
              <v-list-item-title>Dashboards</v-list-item-title>
            </template>

            <v-list-item
              v-for="dashboard in dashboards"
              :key="dashboard.id"
              :to="'/dashboard/' + dashboard.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ dashboard.nome }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/contratos" v-if="checkCredentials(['admin', 'crm'])">
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <div>Consulta de Contratos</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="checkCredentials(['admin', 'editor_regua'])"
            :value="false"
            prepend-icon="mdi-view-dashboard"
            no-action
            class="ml-0"
          >
            <template v-slot:activator>
              <v-list-item-title>Réguas de Comunicação</v-list-item-title>
            </template>

            <v-list-item to="/regua/tipo/predefinidas" class="ml-0">
              <v-list-item-content>
                <v-list-item-title>Pré-definidas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/regua/tipo/movimentacao">
              <v-list-item-content>
                <v-list-item-title>Movimentação</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            v-if="checkCredentials(['admin', 'editor_regua'])"
            :value="false"
            prepend-icon="mdi-bullhorn"
            no-action
            class="ml-0"
          >
            <template v-slot:activator>
              <v-list-item-title>Templates</v-list-item-title>
            </template>
            <v-list-item
              to="/emails"
              v-if="checkCredentials(['admin', 'empresa', 'editor_fatura'])"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <div>E-mails</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/iniciativas-whatsapp" class="ml-0">
              <v-list-item-content>
                <v-list-item-title>WhatsApp</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/nps">
              <v-list-item-content>
                <v-list-item-title>
                  <div>NPS</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/pesquisas"
              v-if="checkCredentials(['admin', 'empresa', 'editor_fatura'])"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <div>Pesquisas</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item to="/fatura" v-if="checkCredentials(['admin', 'empresa', 'editor_fatura'])">
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <div>Controle de Faturas</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            :value="false"
            prepend-icon="mdi-cog"
            no-action
            class=""
            v-if="checkCredentials(['admin', 'empresa', 'editor_empresa'])"
          >
            <template v-slot:activator>
              <v-list-item-title>Configurações</v-list-item-title>
            </template>
            <v-list-item
              v-for="item in itemsConfiguracao"
              :key="item.title"
              link
              :to="item.to"
              class="pl-12"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="d-flex px-2">
          <v-spacer></v-spacer>

          <div
            v-if="!mini"
            class="pt-1 text-caption text--secondary"
            @click="$router.push('/info')"
          >
            v{{ $store.state.app.version }}
          </div>

          <v-spacer></v-spacer>

          <v-btn v-if="!mini" icon @click.stop="toggleDrawerMini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn v-if="mini" icon @click.stop="toggleDrawerMini">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { checkCredentials } from "@/assets/js/utils";
import AvatarMenu from "@/components/AvatarMenu";
import { mapGetters, mapMutations } from "vuex";
import Cookies from "js-cookie";
import enviroment from "@/assets/js/enviroment";

export default {
  components: {
    AvatarMenu,
  },
  data() {
    return {
      mini: Cookies.get("meLembreDrawerMini") == "true",
      itemsConfiguracao: [
        {
          title: "Empresa",
          icon: "mdi-domain",
          to: "/configuracoes/empresa",
        },
        {
          title: "Fluxos automáticos",
          icon: "mdi-sitemap",
          to: "/configuracoes/fluxos_automaticos",
        },
        {
          title: "Precificação",
          icon: "mdi-cash",
          to: "/configuracoes/precificacao",
        },
        {
          title: "Perfis empresa",
          icon: "mdi-office-building-plus",
          to: "/configuracoes/perfis_empresa",
        },
        {
          title: "FAQs",
          icon: "mdi-frequently-asked-questions",
          to: "/configuracoes/faqs",
        },
      ],
    };
  },

  methods: {
    checkCredentials,
    ...mapMutations("empresa", {
      setEmpresaSelecionada: "setEmpresaSelecionada",
    }),

    toggleDrawerMini() {
      this.mini = !this.mini;
      Cookies.set("meLembreDrawerMini", this.mini, {
        domain: enviroment.cookiesDomain,
        path: "/",
      });
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
      empresas: "empresas",
    }),
    ...mapGetters("auth", {
      usuario: "getUsuario",
    }),

    /** Retorna a lista de dashboards da empresa. */
    dashboards() {
      if (!this.$store.state.empresa) {
        return [];
      }

      if (!this.$store.state.empresa.empresaSelecionada) {
        return [];
      }

      return this.$store.state.empresa.empresaSelecionada.dashboard || [];
    },

    empresa: {
      get() {
        return this.empresaSelecionada;
      },
      set(empresa) {
        this.setEmpresaSelecionada(empresa);
      },
    },

    /** Lista das empresas ordenadas alfabeticamente. */
    empresas() {
      if (!this.usuario) {
        return [];
      }
      const sorted = this.usuario.empresas;
      return sorted.sort((empresaA, empresaB) => {
        return empresaA.id < empresaB.id ? -1 : 1;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-application.theme--light {
  #toolbar-logo {
    background-color: #d2dcff;
  }
}

:deep(.v-list-item__icon:first-child) {
  margin-right: 15px !important;
}
:deep(.v-list-item__icon.v-list-group__header__append-icon) {
  min-width: 0px !important;
}
</style>
