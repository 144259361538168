export const rotas = [
  {
    path: "/fatura",
    name: "Fatura",
    component: () => import(/* webpackChunkName: "console" */ "./Fatura.vue"),
    meta: {
      title: "Fatura",
    },
  },
  {
    path: "/fatura/:id",
    name: "VerFatura",
    component: () =>
      import(/* webpackChunkName: "console" */ "./VerFatura.vue"),
    meta: {
      title: "Fatura",
    },
  },
];
