import store from "../../store/index";

export const checkCredentials = function (payload) {
  const grantedCredentials = payload;
  const { permissoes: credentials } = store.getters["auth/getUsuario"];
  let granted = false;
  try {
    credentials.forEach((credential) => {
      if (!grantedCredentials.some((rule) => rule == credential)) return;
      granted = true;
    });
    return granted;
  } catch {
    return false;
  }
};

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function formataCasasDecimais(value) {
  if (!value) return 0;
  if ((typeof value !== "number" && typeof value !== "string") || isNaN(value))
    return 0;

  const valorDecimal = parseFloat(value);
  const decimais = String(valorDecimal).split(".");

  if (decimais[1]) {
    const arrDecimais = Array.from(decimais[1]);
    for (let i = arrDecimais.length + 1; i == 0; i--) {
      if (arrDecimais[i] === 0) arrDecimais.pop();
      else break;
    }
  }

  const numAbsoluto = Math.abs(decimais[0]);
  let numDecimal = 0;

  if (!decimais[1]) {
    numDecimal = "00";
  } else {
    decimais[1].length < 2
      ? (numDecimal = decimais[1] + "0")
      : (numDecimal = decimais[1]);
  }

  if (!decimais[1]) {
    return parseFloat(`${numAbsoluto}.${numDecimal}`).toFixed(2);
  }

  return parseFloat(`${numAbsoluto}.${numDecimal}`);
}
