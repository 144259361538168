import http from "@/plugins/axios";
import oauth from "../services/oauth";
import enviroment from "@/assets/js/enviroment";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import environment from "@/assets/js/enviroment";


const state = {
  usuario: {
    empresas: [],
  },
  token: null,
};

const mutations = {
  setToken(state, token) {
    console.debug("Salvando o token de autenticação.");

    state.token = token;
    Cookies.set("melembreAuthToken", token, {
      domain: enviroment.cookiesDomain,
      path: "/",
    });
    http.setTokenCoreApi(token);
    http.setTokenDadosApi(token);

    let urlContinue = Cookies.get("urlContinue");

    if (urlContinue && urlContinue != "null") {
      Cookies.remove("urlContinue", {
        domain: enviroment.cookiesDomain,
        path: "/",
      });
      window.location = urlContinue;
    }
  },
  setUsuario(state, usuario) {
    console.debug("Salvando o usuário autenticado:", usuario.email);
    state.usuario = usuario;
    Cookies.set("melembreUsuario", JSON.stringify(usuario), {
      domain: enviroment.cookiesDomain,
      path: "/",
    });

    Sentry.setUser({
      id: usuario.id,
      email: usuario.email,
      username: usuario.nome,
    });
  },
};

const actions = {
  auth({ commit, dispatch }, token) {
    return new Promise(async (resolve) => {
      if (token) {
        commit("setToken", token);
      } else {
        console.error("Autenticação sem token.");
        irParaQuiteJaAuth();
        return;
      }

      console.debug("Consultando o usuário autenticado.");
      await oauth
        .me()
        .then((res) => {
          console.debug("Usuário autenticado:", res.data.email);
          commit("setUsuario", res.data);
          commit("empresa/setEmpresas", res.data.empresas, { root: true });
          resolve();
        })
        .catch((erro) => {
          console.error("Não foi possível buscar o usuário autenticado.", erro);
          dispatch("logout");
          irParaQuiteJaAuth();
        });
    });
  },
  logout() {
    console.debug("Deslogando.");

    state.usuario = null;
    state.token = null;

    Cookies.remove("melembreAuthToken");
    Cookies.remove("melembreUsuario");
  },
  async verificarAutenticacao({ commit, dispatch }) {
    if (state.usuario && state.usuario.id && state.token) {
      return true;
    }
    const token = Cookies.get("melembreAuthToken");

    if (token) {
      commit("setToken", token);
      http.setTokenCoreApi(token);
      http.setTokenDadosApi(token);
    } else {
      console.debug("Token de autenticação não encontrado.");
      irParaQuiteJaAuth();
      return;
    }

    console.debug("Consultando o usuário autenticado.");

    await oauth
      .me()
      .then((res) => {
        commit("setUsuario", res.data);
        const chaveEmpresaSelecionada = Cookies.get('meLembreEmpresaSelecionada')
        const empresaSelecionada = res.data.empresas.find(e => e.chave === chaveEmpresaSelecionada) || res.data.empresas[0]
        commit("empresa/setEmpresaSelecionada", empresaSelecionada, { root: true });
        commit("empresa/setEmpresas", res.data.empresas, { root: true });
      })
      .catch((erro) => {
        console.error("Não foi possível buscar o usuário autenticado.", erro);

        let resposta = erro.response || {};

        if (resposta.status == 401) {
          dispatch("logout");
          irParaQuiteJaAuth();
          return;
        }

        throw erro;
      });

    return true;
  },
};

const getters = {
  getUsuario({ usuario }) {
    return usuario;
  },
};

function irParaQuiteJaAuth() {
  Cookies.set("urlContinue", window.location.href, {
    domain: environment.cookiesDomain,
    path: "/",
  });
  window.location = enviroment.coreApiUrl + "/oauth/login";
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
