export const rotas = [
  {
    path: "/agenda",
    name: "Agenda",
    component: () => import(/* webpackChunkName: "console" */ "./Agenda.vue"),
    meta: {
      title: "Agenda",
    },
  },
];
