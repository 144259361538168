import Vue from "vue";
import store from "@/store";
import routes from "./routes";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    ...routes,

    {
      path: "/",
      name: "Home",
      redirect: "/regua/tipo/predefinidas",
    },
    {
      path: "/autenticar",
      name: "Autenticar",
      beforeEnter: async (to, from, next) => {
        await store.dispatch("auth/auth", to.query.token);
        next("/")
      },
      meta: {
        precisaAutenticacao: false,
      },
    },
  ],
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - Console do MeLembre";
  } else {
    document.title = "Console do MeLembre";
  }

  let precisaAutenticacao =
    to.meta.precisaAutenticacao === undefined || to.meta.precisaAutenticacao;
  let precisaEmpresa =
    to.meta.precisaEmpresa === undefined || to.meta.precisaEmpresa;

  if (!precisaAutenticacao) {
    return next();
  }

  return store
    .dispatch("auth/verificarAutenticacao")
    .then((estaAutenticado) => {
      if (!estaAutenticado) {
        return next({ name: "Autenticar" });
      }

      let empresas = store.state.auth.usuario.empresas;
      
      if (to.query?.empresa) {
        let empresa = empresas.find(
          (empresa) => empresa?.chave == to.query?.empresa
        );
        if (empresa) store.commit("empresa/setEmpresaSelecionada", empresa);
      }

      if (precisaEmpresa && empresas.length == 0) {
        console.debug("Nenhuma empresa configurada.");
        return next({ name: "AcessoNaoConfigurado" });
      }

      return next();
    })
    .catch(() => {
      return next({ name: "Erro" });
    });
});

router.onError((error) => {
  console.error("Não foi possível carregar a rota.", error);
});

window.$router = router;

export default router;
